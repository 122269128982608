import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

const StoveListStyles = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  li {
    width: calc(50% - 20px);
    margin-top: 40px;
    :nth-child(1),
    :nth-child(2) {
      margin-top: 0;
    }
    :nth-child(odd) {
      margin-right: 40px;
    }
  }
  figure {
    display: block;
    margin: 0;
    padding: 20px;
    border: 1px solid var(--black);
    border-bottom: 0;
    img {
      display: block;
      width: 100%;
    }
  }
  figcaption {
    padding: 30px;
    background: var(--black);
    transition: background 0.2s ease-in-out;
  }
  a:hover {
    figcaption {
      background: var(--green);
    }
  }
  h3 {
    font-family: 'RobotoSlab';
    color: var(--white);
    font-weight: 700;
    text-align: center;
  }
  @media (max-width: 767px) {
    figure {
      padding: 10px;
    }
    figcaption {
      padding: 20px 10px;
    }
    li {
      width: calc(50% - 10px);
      margin-top: 20px;
      :nth-child(odd) {
        margin-right: 20px;
      }
    }
  }
  @media (max-width: 499px) {
    li {
      width: 100%;
      margin-top: 20px !important;
      margin-right: 0 !important;
      :first-child {
        margin-top: 0 !important;
      }
    }
  }
`;

export default function StoveList({ families, parentUrl }) {
  return (
    <StoveListStyles>
      {families.map((item) => (
        <li key={item.id}>
          <Link to={`/${parentUrl}/${item.url.current}`}>
            <figure>
              <Img fluid={item.image.asset.fluid} alt={item.name} />
            </figure>
            <figcaption>
              <h3>{item.name}</h3>
            </figcaption>
          </Link>
        </li>
      ))}
    </StoveListStyles>
  );
}
