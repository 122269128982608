import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SubPageHeader from '../components/SubPageHeader';
import StoveFamilyList from '../components/StoveFamilyItems';

export default function BuildingPage({ data }) {
  const families = data.families.nodes;
  return (
    <Layout>
      <SubPageHeader title="Building" />
      <StoveFamilyList families={families} parentUrl="building" />
    </Layout>
  );
}

export const query = graphql`
  query {
    families: allSanityStoveFamily {
      nodes {
        id
        name
        image {
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
        url {
          current
        }
      }
    }
  }
`;
